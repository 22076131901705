import { NO_EMOJI_REGEX } from '@shopopop/backoffice-frontend-utils';
import { AutoComplete, Col, Form, Input, InputNumber, Radio, Row } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NO_SPECIAL_CHARACTERS_REGEX } from '../../../../../utils/src/regex/regex';
import { AutocompleteStatusProps } from '../../../interfaces/AutocompleteStatusProps.ts';
import { AddressOption } from '../../../interfaces/RecipientAddressInformationProps';
import StructuredInfoCard from '../../ContentCard/StructuredInfoCard';
import HighlightText from '../../HighlightText.tsx';

const { TextArea } = Input;

interface RecipientAddressFormProps {
  options: AddressOption[];
  searchTerm: string;
  validateStatus: AutocompleteStatusProps;
  setValidateStatus: (status: AutocompleteStatusProps) => void;
  onSearch: (value: string) => void;
  onSelect: (value: string) => void;
  onClear: () => void;
  showOriginalAddress: string | null;
  showAdditionalInfo: boolean;
}

function RecipientAddressFormItems({
  options,
  searchTerm,
  validateStatus,
  setValidateStatus,
  onSearch,
  onSelect,
  onClear,
  showOriginalAddress,
  showAdditionalInfo,
}: RecipientAddressFormProps): ReactNode {
  const { t } = useTranslation();

  return (
    <Row gutter={16}>
      <Col xs={24}>
        <Form.Item
          name="address"
          label={t('RCP_ADDRESS')}
          hasFeedback
          validateStatus={validateStatus}
          validateTrigger="onBlur"
          rules={[
            { required: true, message: t('RCP_ADDRESS_REQUIRED') },
            {
              pattern: NO_EMOJI_REGEX,
              message: t('INVALID_VALUE'),
            },
            () => ({
              validator() {
                if (searchTerm.length >= 3) {
                  setValidateStatus('error');
                  return Promise.reject(new Error(t('ERROR_OCCURED_ON_ADDRESS_CREATION')));
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <AutoComplete
            data-testid="address-search"
            options={options.map((option) => ({
              value: JSON.stringify({
                address: option.address,
                placeId: option.placeId,
              }),
              label: (
                <div>
                  <HighlightText text={option.address} highlightTag={searchTerm} />
                </div>
              ),
            }))}
            onSearch={onSearch}
            onSelect={onSelect}
            onClear={onClear}
          />
        </Form.Item>
      </Col>
      {showOriginalAddress && (
        <Col xs={24}>
          <Form.Item>
            <StructuredInfoCard label={t('RCP_ORIGINAL_ADDRESS')} value={showOriginalAddress} direction="vertical" />
          </Form.Item>
        </Col>
      )}
      {showAdditionalInfo && (
        <>
          <Col xs={24} xl={12}>
            <Form.Item name="elevator" label={t('LIFT')} rules={[{ required: true, message: t('LIFT_REQUIRED') }]}>
              <Radio.Group
                data-testid="elevator-radio"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Radio.Button value={'yes'} style={{ flex: 1, textAlign: 'center' }}>
                  {t('LIFT_YES')}
                </Radio.Button>
                <Radio.Button value={'no'} style={{ flex: 1, textAlign: 'center' }}>
                  {t('LIFT_NO')}
                </Radio.Button>
                <Radio.Button
                  value={'dontknow'}
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('LIFT_UNKNOWN')}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item
              name="floor"
              label={t('FLOOR')}
              rules={[
                {
                  type: 'number',
                  min: 0,
                  max: 40,
                  message: t('FLOOR_LIMIT_LENGHT'),
                },
                {
                  pattern: NO_SPECIAL_CHARACTERS_REGEX,
                  message: t('INVALID_VALUE'),
                },
                {
                  pattern: NO_EMOJI_REGEX,
                  message: t('INVALID_VALUE'),
                },
              ]}
            >
              <InputNumber data-testid="floor-input" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="comment"
              label={<span>{t('ADDRESS_ADDITIONAL_INFO')}</span>}
              rules={[
                {
                  pattern: NO_EMOJI_REGEX,
                  message: t('INVALID_VALUE'),
                },
              ]}
            >
              <TextArea showCount maxLength={255} data-testid="comment-input" style={{ height: 52 }} />
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  );
}

export default RecipientAddressFormItems;
