import { formatEmail, formatPhoneNumber } from '@shopopop/backoffice-frontend-utils';
import { Card, Col, Row, Typography } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { RecipientSummaryProps } from '../../interfaces/RecipientInformationProps';

const { Text } = Typography;

function RecipientSummaryCard({ address, email, fullName, telephone, additionalInfo }: Readonly<RecipientSummaryProps>): ReactNode {
  const { t } = useTranslation();

  const formattedPhoneNumber = formatPhoneNumber(telephone);
  const formattedEmail = formatEmail(email);

  return (
    <Card title={t('RECAP_RCP_INFO_TITLE')}>
      <Row gutter={[0, 16]}>
        <Col xs={24} lg={12} xxl={8}>
          <Text type="secondary">{t('RCP_TABLE_NAME')} : </Text>
          <Text>{fullName}</Text>
        </Col>
        <Col xs={24} lg={12} xxl={8}>
          <Text type="secondary">{t('RCP_TELEPHONE')} : </Text>
          <Text>{formattedPhoneNumber}</Text>
        </Col>
        <Col xs={24} lg={24} xxl={8}>
          <Text type="secondary">{t('RCP_EMAIL')} : </Text>
          <Text>{formattedEmail || '-'}</Text>
        </Col>
        <Col xs={24}>
          <Text type="secondary">{t('RECAP_ADDRESS')} : </Text>
          <Text>{address}</Text>
        </Col>
        {additionalInfo && (
          <Col xs={24}>
            <Text type="secondary">{t('ADDRESS_ADDITIONAL_INFO')} : </Text>
            <Text>{additionalInfo}</Text>
          </Col>
        )}
      </Row>
    </Card>
  );
}

export default RecipientSummaryCard;
