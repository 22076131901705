import { UserAddOutlined } from '@ant-design/icons';
import { formatEmail, formatPhoneNumber, smoothScrollToTop } from '@shopopop/backoffice-frontend-utils';
import { useScreenSize } from '@shopopop/react-hooks';
import { Button, Card, ConfigProvider, Flex, Pagination, Skeleton, Space, Table, TableColumnsType, Typography } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchResultsCount } from '../../index.ts';
import { SearchRecipientsTableDataType } from '../../interfaces/SearchRecipientsList';
import EmptyNobody from '../Empty/EmptyNobody.tsx';

const { Link, Text } = Typography;

interface SearchRecipientsTableProps {
  dataSource: SearchRecipientsTableDataType[];
  total: number;
  onSelectRecipient: (recipient: SearchRecipientsTableDataType) => void;
  isLoading: boolean;
  handleSelectCreateRecipient: () => void;
  handlePageChange: (page: number, pageSize?: number) => void;
  noResultLinkMessage: string;
}

/**
 * @return {ReactNode} SearchRecipientsTable
 */
function SearchRecipientsTable({ dataSource, total, onSelectRecipient, isLoading, handleSelectCreateRecipient, handlePageChange, noResultLinkMessage }: SearchRecipientsTableProps): ReactNode {
  const { t } = useTranslation();
  const { tabletMode } = useScreenSize();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(tabletMode ? 5 : 10);

  const columns: TableColumnsType<SearchRecipientsTableDataType> = [
    {
      title: t('RCP_TABLE_NAME'),
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: t('RCP_TABLE_EMAIL'),
      dataIndex: 'email',
      key: 'email',
      render: (email) => formatEmail(email),
    },
    {
      title: t('RCP_TABLE_PHONE_NUMBER'),
      dataIndex: 'telephone',
      key: 'telephone',
      render: (telephone) => formatPhoneNumber(telephone),
    },
    {
      title: t('RCP_TABLE_ACTION'),
      dataIndex: 'Action',
      key: 'action',
      render: (_, recipient) => (
        // TODO: Sélection de l'utilisateur dans la page de résultat lors de l'intégration de la page de création de livraison
        <Link
          onClick={() => {
            smoothScrollToTop();
            onSelectRecipient(recipient);
          }}
        >
          {t('SELECT_RCP')}
        </Link>
      ),
    },
  ];

  useEffect(() => {
    setPageSize(tabletMode ? 5 : 10);
    setCurrentPage(1);
  }, [tabletMode]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
    const pageSize = tabletMode ? 5 : 10;
    handlePageChange(page, pageSize);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const AdditionalMessage = () => {
    return (
      <Space
        direction={'vertical'}
        align="center"
        style={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Text>{t('SELECT_RCP_MSG')}</Text>
        <Button type="primary" onClick={handleSelectCreateRecipient} icon={<UserAddOutlined />}>
          {t('CREATE_NEW_RCP_TAB')}
        </Button>
      </Space>
    );
  };

  return !tabletMode ? (
    <ConfigProvider renderEmpty={() => <EmptyNobody handleClickLink={handleSelectCreateRecipient} noResultLinkMessage={noResultLinkMessage} />}>
      <Space direction={'vertical'}>
        <SearchResultsCount count={total} />
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            pageSize: 10,
            total: total,
            current: currentPage,
            onChange: handlePaginationChange,
          }}
        />
        {dataSource.length !== 0 && <AdditionalMessage />}
      </Space>
    </ConfigProvider>
  ) : (
    <Flex vertical gap={20}>
      <Flex vertical gap={10}>
        {isLoading && (
          <Card loading={isLoading}>
            <Skeleton />
          </Card>
        )}
        {!isLoading &&
          dataSource.slice(startIndex, endIndex).map((recipient) => (
            <Card
              key={recipient.id}
              title={recipient.fullName}
              extra={
                <Link
                  onClick={() => {
                    smoothScrollToTop();
                    onSelectRecipient(recipient);
                  }}
                >
                  {t('SELECT_RCP')}
                </Link>
              }
            >
              <div>{formatPhoneNumber(recipient.telephone)}</div>
              <div>{formatEmail(recipient.email)}</div>
            </Card>
          ))}
        {!isLoading && dataSource.length === 0 && (
          <Card>
            <EmptyNobody handleClickLink={handleSelectCreateRecipient} noResultLinkMessage={noResultLinkMessage} />
          </Card>
        )}
      </Flex>
      <Pagination responsive defaultCurrent={1} total={total} onChange={handlePageChange} pageSize={5} showSizeChanger={false} style={{ alignSelf: 'center', marginBottom: 20 }} />
      {dataSource.length !== 0 && <AdditionalMessage />}
    </Flex>
  );
}

export default SearchRecipientsTable;
